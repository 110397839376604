<div class="container-fluid p-0">
    <div class="row mb-2 mb-xl-3">
        <div class="col-auto d-none d-sm-block">
            <h3>Agregar muestra</h3>
        </div>

        <div class="col-auto ms-auto text-end mt-n1">
        </div>
    </div>

    <div class="row justify-content-center">
        <div class="col-10">
            <ul class="stepper stepper-horizontal">
                <li class="completed">
                    <a [routerLink]="" class="px-2">
                        <span class="circle">1</span><span class="label">Datos del componente</span>
                    </a>
                </li>

                <li class="active">
                    <a [routerLink]="" class="px-2">
                        <span class="circle">2</span><span class="label">Datos de la muestra</span>
                    </a>
                </li>

                <li>
                    <a [routerLink]="" class="px-2">
                        <span class="circle">3</span><span class="label">Comprobante</span>
                    </a>
                </li>

            </ul>
        </div>
    </div>

    <div class="d-none d-xl-block">
        <div class="row justify-content-center">
            <div class="col">
                <form #registraMuestraForm="ngForm">

                <div class="card">
                        <table class="table table-hover my-0" id="tabla-ingreso-masivo">
                            <thead>
                                <tr>
                                    <th>ID Componente</th>
                                    <th>Número envase</th>
                                    <th>Fecha toma de muestra</th>
                                    <th>Plan Analisís</th>
                                    <th>Uso componente total<br/><small>(Opcional)</small></th>
                                    <th>Uso componente desde<br/>cambio lubricante<small>(Opcional)</small></th>
                                    <th>Relleno desde último cambio<br><small>(Opcional)</small></th>
                                    <th>&nbsp;</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let rm of model.registrosMuestras;index as i">
                                    <td>
                                        <ng-template #popContent>
                                           <div class="row">
                                                <div class="col">
                                                    <small>
                                                        <p class="card-text mb-1"><strong>{{rm.componente.tipoComponente.nombre}}</strong><br> {{rm.componente.marca}}, {{!rm.componente.modelo ? 'sin modelo'
                                                            : rm.componente.modelo}}<br> {{rm.componente.ubicacion}}
                                                            <br>
                                                        </p>
                                                    </small>
                                                </div>
                                                <div class="col ">
                                                    <small>
                                                        <p class="card-text mb-1">
                                                            <strong>{{rm.componente.equipo?.tipoEquipo?.nombre}}</strong><br> {{rm.componente.equipo?.marca}}
                                                            <br> {{rm.componente.equipo?.modelo}}
                                                        </p>
                                                    </small>
                                                </div>
                                           </div>
                                           <div class="row">
                                                <div class="col">
                                                    <small>
                                                        <p class="card-text mb-1"><strong>Lubricante</strong><br>
                                                            {{rm.componente.lubricante?.nombre}}<br>
                                                            <br>
                                                        </p>
                                                    </small>
                                                </div>
                                                <div class="col ">
                                                    <small>
                                                        <p class="card-text mb-1">
                                                            <strong>Plan de an&aacute;lisis</strong>
                                                            <br> {{rm.componente.planAnalisis?.nombre}}
                                                        </p>
                                                    </small>
                                                </div>
                                            </div>
                                        </ng-template>
                                        <a [ngbPopover]="popContent" #p="ngbPopover" placement="end"  triggers="mouseenter:mouseleave"><strong>
                                          {{rm.componente.componenteId}}</strong></a>
                                    </td>
                                    <td>
                                        <input type="text" class="form-control input-sm" id="numeroEnvaseMuestra_{{rm.componente.componenteId}}"
                                        name="numeroEnvaseMuestra_{{rm.componente.componenteId}}"
                                        maxlength="9" min="1"
                                        [(ngModel)]="rm.muestraId" (change)="changeNumeroEnvase(model.errores[i],i)"
                                        #numeroEnvaseMuestra="ngModel" [unicoNumeroEnvase]="model.registrosMuestras" [index]="i"
                                        [ngClass]="{'is-invalid':numeroEnvaseMuestra.invalid
                                        && (numeroEnvaseMuestra.dirty || numeroEnvaseMuestra.touched || isDirtyOrtouched(registraMuestraForm2.form,'numeroEnvaseMuestra',rm.componente.componenteId))||sampleHasErrors(i)}"
                                        required
                                        validaMuestraId
                                        >
                                        <div *ngIf="numeroEnvaseMuestra.invalid && (numeroEnvaseMuestra.dirty || numeroEnvaseMuestra.touched || isDirtyOrtouched(registraMuestraForm2.form,'numeroEnvaseMuestra',rm.componente.componenteId))|| sampleHasErrors(i)" class="text-danger">
                                            <div *ngIf="numeroEnvaseMuestra.errors?.unicoNumeroEnvase || sampleHasErrors(i)">
                                                Número de envase utilizado anteriormente.
                                            </div>
                                            <div *ngIf="numeroEnvaseMuestra.value !=='' && numeroEnvaseMuestra.errors?.validacionRut">
                                                Formato incorrecto.
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <input type="date" onkeydown="return false"
                                        max="{{ model.todaysdate | date :'yyyy-MM-dd' }}" validarFecha
                                        class="form-control" id="fechaTomaMuestra_{{rm.componente.componenteId}}" name="fechaTomaMuestra_{{rm.componente.componenteId}}" [(ngModel)]="rm.fechaMuestreo"
                                        #fechaTomaMuestra="ngModel" [ngClass]="{'is-invalid':fechaTomaMuestra.invalid
                                        && (fechaTomaMuestra.dirty || fechaTomaMuestra.touched || isDirtyOrtouched(registraMuestraForm2.form,'fechaTomaMuestra',rm.componente.componenteId))}" (keydown.enter)="enter($event)" required>
                                    </td>
                                    <td style="max-width: 300px;">
                                        <select class="form-select"
                                                placeholder="Seleccionar Plan de Analisis"
                                                id="contratoPlanAnalisisId{{ rm.componente.componenteId }}"
                                                name="contratoPlanAnalisisId{{ rm.componente.componenteId }}"
                                                #selectContratoPlanAnalisisId="ngModel"
                                                [(ngModel)]="rm.planAnalisis"

                                                [ngClass]="{
                                                    'is-invalid':
                                                    selectContratoPlanAnalisisId.invalid && (selectContratoPlanAnalisisId.dirty || selectContratoPlanAnalisisId.touched)
                                                }"
                                                required
                                        >
                                            <option *ngFor="let plan of planesComponenteList[rm.componente.componenteId].planes" [ngValue]="plan">
                                                {{ plan.nombre }}
                                            </option>
                                        </select>
                                        <div *ngIf="selectContratoPlanAnalisisId.invalid && (selectContratoPlanAnalisisId.dirty || selectContratoPlanAnalisisId.touched)" class="text-danger">
                                            <div *ngIf="selectContratoPlanAnalisisId.errors?.required">
                                                Debe seleccionar plan de análisis.
                                            </div>
                                        </div>

                                        <div *ngIf="!download_planes && planesComponenteList[rm.componente.componenteId].planes.length === 0">
                                            <span class="text-danger">No existe un plan de análisis habilitado para este tipo de componente, favor contactar a personal del laboratorio. <a href="mailto:laboratoriolubricantes@copec.cl">laboratoriolubricantes@copec.cl</a></span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex gap-2 align-items-center">
                                            <div class="">
                                                <input type="text" class="form-control input-sm"  numeric decimals="0"  maxlength="10"  id="usoTotalComponente_{{rm.componente.componenteId}}" name="usoTotalComponente_{{rm.componente.componenteId}}" [(ngModel)]="rm.usoTotalComponente">
                                            </div>
                                            <div class="">
                                                <select class="form-select"  id="unidadUsoTotalComponente_{{rm.componente.componenteId}}" name="unidadUsoTotalComponente_{{rm.componente.componenteId}}" [(ngModel)]="rm.unidadUsoTotalComponente">
                                                  <option value="horas" selected>hrs</option>
                                                  <option value="km">km</option>
                                                </select>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                       <div class="d-flex gap-2 align-items-center">
                                          <div class="">
                                            <input type="text" class="form-control input-sm" numeric decimals="0" maxlength="10" id="usoCambioLubricante_{{rm.componente.componenteId}}" name="usoCambioLubricante_{{rm.componente.componenteId}}" [(ngModel)]="rm.usoCambioLubricante">
                                          </div>
                                          <div class="">
                                            <select class="form-select"  id="unidadUsoCambioLubricante_{{rm.componente.componenteId}}" name="unidadUsoCambioLubricante_{{rm.componente.componenteId}}" [(ngModel)]="rm.unidadUsoCambioLubricante">
                                              <option value="horas" selected>hrs</option>
                                              <option value="km">km</option>
                                            </select>
                                          </div>
                                       </div>
                                    </td>
                                    <td>
                                        <div class="input-group">
                                            <input type="text" class="form-control input-sm" numeric decimals="0" maxlength="10"  id="rellenoDesdeUltimoCambio_{{rm.componente.componenteId}}" name="rellenoDesdeUltimoCambio_{{rm.componente.componenteId}}" [(ngModel)]="rm.rellenoDesdeUltimoCambio">
                                          <span class="input-group-text">litros</span>
                                        </div>
                                    </td>
                                    <td>
                                      <button class="btn p-0 fs-4 btn-icon" matTooltip="Agregar comentario" TooltipPosition="top" aria-label="Agregar comentario" (click)="comentar(rm)">

                                        <i *ngIf="rm.comentarioCliente && rm.comentarioCliente !== ''" class="bi bi-chat-fill text-primary"></i>

                                        <i *ngIf="!rm.comentarioCliente || rm.comentarioCliente === ''" class="bi bi-chat"></i>
                                      </button>
                                    </td>
                                    <td>
                                      <button class="btn p-0 fs-4 btn-icon" (click)="eliminarRegistroMuestra(rm.componente.componenteId,i)">
                                        <i class="bi bi-x-circle"></i>
                                      </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                </div>
                <div class="row mb-5">
                        <div class="col-7">
                            <ul class="text-muted">
                            <li><small><strong>Uso componente:</strong> total de horas o km de uso que tiene el componente</small></li>
                            <li><small><strong>Uso lubricante:</strong> total de horas o km de uso que tiene el lubricante que tiene el componente</small></li>
                            <li><small><strong>Relleno:</strong> cantidad de lubricante de relleno que se ha echado al componente desde su último cambio de aceite</small></li>
                            </ul>
                        </div>
                        <div class="col text-end">
                            <button class="btn btn-link btn-lg" (click)="cancelar()">Cancelar</button>
                            <button class="btn btn-success btn-lg" [disabled]="registraMuestraForm.invalid" (click)="registrarMuestras()">Ingresar las muestras</button>
                        </div>
                </div>
            </form>
            </div>
        </div>
    </div>


    <div class="d-block d-xl-none">
        <div class="row justify-content-center">
           <div class="col-xxl-12 col-xl-12 col-lg-10">

                <form #registraMuestraForm2="ngForm">

                    <div class="card text-dark mb-3 border" *ngFor="let rm of model.registrosMuestras;index as i">
                        <div class="card-header bg-light">
                            <div class="row">
                                <ng-template #popContent>
                                    <div class="row">
                                        <div class="col">
                                            <small>
                                                <p class="card-text mb-1"><strong>{{rm.componente.tipoComponente.nombre}}</strong><br> {{rm.componente.marca}}, {{!rm.componente.modelo ? 'sin modelo'
                                                    : rm.componente.modelo}}<br> {{rm.componente.ubicacion}}
                                                    <br>
                                                </p>
                                            </small>
                                        </div>
                                        <div class="col ">
                                            <small>
                                                <p class="card-text mb-1">
                                                    <strong>{{rm.componente.equipo?.tipoEquipo?.nombre}}</strong><br> {{rm.componente.equipo?.marca}}
                                                    <br> {{rm.componente.equipo?.modelo}}
                                                </p>
                                            </small>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <small>
                                                <p class="card-text mb-1"><strong>Lubricante</strong><br>
                                                    {{rm.componente.lubricante?.nombre}}<br>
                                                    <br>
                                                </p>
                                            </small>
                                        </div>
                                        <div class="col ">
                                            <small>
                                                <p class="card-text mb-1">
                                                    <strong>Plan de an&aacute;lisis</strong>
                                                    <br> {{rm.componente.planAnalisis?.nombre}}
                                                </p>
                                            </small>
                                        </div>
                                    </div>
                                </ng-template>
                            <div class="col"><a [ngbPopover]="popContent" #p="ngbPopover" placement="end"  triggers="mouseenter:mouseleave"><strong>Componente ID {{rm.componente.componenteId}}</strong></a></div>
                            <div class="col text-end">
                              <a (click)="eliminarRegistroMuestra(rm.componente.componenteId,i)"><i class="bi bi-x-circle"></i></a> </div>
                        </div>
                        </div>
                        <div class="card-body">
                        <div class="row">
                            <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div class="mb-3">
                                    <label class="form-label">Número envase de la muestra</label>
                                    <input type="text" class="form-control" id="numeroEnvaseMuestra{{rm.componente.componenteId}}"
                                    name="numeroEnvaseMuestra{{rm.componente.componenteId}}"
                                    maxlength="9" min="1"
                                    [(ngModel)]="rm.muestraId" (change)="changeNumeroEnvase(model.errores[i],i)"
                                    #numeroEnvaseMuestra1="ngModel" [unicoNumeroEnvase]="model.registrosMuestras" [index]="i"
                                    [ngClass]="{'is-invalid':numeroEnvaseMuestra1.invalid
                                    && (numeroEnvaseMuestra1.dirty || numeroEnvaseMuestra1.touched || isDirtyOrtouched(registraMuestraForm.form,'numeroEnvaseMuestra',rm.componente.componenteId,'_') )|| sampleHasErrors(i)}" required
                                    validaMuestraId
                                    >
                                    <div *ngIf="numeroEnvaseMuestra1.invalid && (numeroEnvaseMuestra1.dirty || numeroEnvaseMuestra1.touched  || isDirtyOrtouched(registraMuestraForm.form,'numeroEnvaseMuestra',rm.componente.componenteId,'_') )|| sampleHasErrors(i)" class="text-danger">
                                        <div *ngIf="numeroEnvaseMuestra1.errors?.unicoNumeroEnvase || sampleHasErrors(i)">
                                            Número de envase utilizado anteriormente.
                                        </div>
                                        <div *ngIf="numeroEnvaseMuestra1.value !=='' && numeroEnvaseMuestra1.errors?.validacionRut">
                                            Formato incorrecto.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div class="mb-3">
                                    <label class="form-label">Fecha toma de muestra</label>
                                    <input type="date"
                                    max="{{ model.todaysdate | date :'yyyy-MM-dd' }}" validarFecha
                                    class="form-control" id="fechaTomaMuestra{{rm.componente.componenteId}}" name="fechaTomaMuestra{{rm.componente.componenteId}}" [(ngModel)]="rm.fechaMuestreo"
                                    #fechaTomaMuestra1="ngModel" [ngClass]="{'is-invalid':fechaTomaMuestra1.invalid
                                    && (fechaTomaMuestra1.dirty || fechaTomaMuestra1.touched || isDirtyOrtouched(registraMuestraForm.form,'fechaTomaMuestra',rm.componente.componenteId,'_'))}" required>                         </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div class="mb-3">
                                    <label class="form-label">Plan de análisis</label>
                                    <div class="row">
                                      <div class="col">
                                        <select class="form-select"
                                        id="contratoPlanAnalisisId{{ rm.componente.componenteId }}"
                                        name="contratoPlanAnalisisId{{ rm.componente.componenteId }}"
                                        #selectContratoPlanAnalisisId="ngModel"
                                        [(ngModel)]="rm.planAnalisis"
                                        [ngClass]="{
                                            'is-invalid':
                                            selectContratoPlanAnalisisId.invalid &&
                                            (selectContratoPlanAnalisisId.dirty || selectContratoPlanAnalisisId.touched)
                                        }"
                                        required
                                >
                                    <option *ngFor="let plan of planesComponenteList[rm.componente.componenteId].planes" [ngValue]="plan">
                                        {{ plan.nombre }}
                                    </option>
                                </select>
                                      </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div class="mb-3">
                                    <label class="form-label">Uso total del componente</label>
                                    <div class="row">
                                        <div class="col">
                                            <input type="text" class="form-control"  id="usoTotalComponente{{rm.componente.componenteId}}" name="usoTotalComponente{{rm.componente.componenteId}}" maxlength="10" [(ngModel)]="rm.usoTotalComponente">
                                        </div>
                                    <div class="col">
                                            <select class="form-select"  id="unidadUsoTotalComponente{{rm.componente.componenteId}}" name="unidadUsoTotalComponente{{rm.componente.componenteId}}" [(ngModel)]="rm.unidadUsoTotalComponente">
                                              <option value="horas" selected>hrs</option>
                                              <option value="km">km</option>
                                            </select>
                                    </div>
                                    </div>
                                    <div class="form-text">Opcional</div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div class="mb-3">
                                    <label class="form-label">Uso desde  cambio del lubricante</label>
                                    <div class="row">
                                    <div class="col">
                                        <input type="text" class="form-control" id="usoCambioLubricante{{rm.componente.componenteId}}" name="usoCambioLubricante{{rm.componente.componenteId}}" maxlength="10" [(ngModel)]="rm.usoCambioLubricante" numeric>
                                        </div>
                                    <div class="col">
                                            <select class="form-select"  id="unidadUsoCambioLubricante{{rm.componente.componenteId}}" name="unidadUsoCambioLubricante{{rm.componente.componenteId}}" [(ngModel)]="rm.unidadUsoCambioLubricante">
                                              <option value="horas" selected>hrs</option>
                                              <option value="km">km</option>
                                            </select>
                                    </div>
                                    </div>
                                    <div class="form-text">Opcional</div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div class="mb-3">
                                    <label class="form-label">Relleno desde el último cambio</label>
                                    <div class="input-group">
                                        <input type="text" class="form-control"   id="rellenoDesdeUltimoCambio{{rm.componente.componenteId}}" name="rellenoDesdeUltimoCambio{{rm.componente.componenteId}}" maxlength="10" [(ngModel)]="rm.rellenoDesdeUltimoCambio" numeric>
                                        <span class="input-group-text">litros</span>
                                    </div>
                                    <div class="form-text">Opcional</div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div class="mt-4 mb-5 text-end">
                        <a class="btn btn-link btn-lg">Cancelar</a>
                        <button class="btn btn-success btn-lg" [disabled]="registraMuestraForm2.invalid || existenErrores()" (click)="registrarMuestras()" >Ingresar las muestras</button>
                    </div>
                </form>
            </div>
        </div>
    </div>

</div>
