import { Component, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/auth/authentication.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { environment } from "src/environments/environment";

import * as uuid from 'uuid';
import * as CryptoJS from 'crypto-js';
import { TableauService } from '../services/tableau.service';

declare var tableau: any;

@Component({
    selector: 'app-external-content',
    templateUrl: './ext.component.html',
    styleUrls: ['./ext.component.css']
})
export class ExternalContentComponent implements OnInit, AfterViewInit {
    responsable:{nombre:string, email:string,phone:string}|undefined= {nombre:'', email:'',phone:''};
    viz: any;
    @ViewChild("vizContainer") containerDiv?: ElementRef;

    dashboard_enabled: boolean | undefined;
    urlSafe: SafeResourceUrl = '';

    model: any = {
        current_url: ""
    }

    constructor(
        public authService: AuthenticationService,
        private router: Router,
        public spinner: SpinnerService,
        public sanitizer: DomSanitizer,
        public tableauService: TableauService
    ) {
        this.loadScripts();
    }

    ngOnInit(): void {
        this.dashboard_enabled = this.authService.currentClientValue.faena.dashboard_enabled && this.authService.currentUserValue.preferred_username != "";
        this.model.current_url = this.authService.currentClientValue.faena.dashboard_url === undefined ? "" : this.authService.currentClientValue.faena.dashboard_url;
        this.responsable = this.authService.currentClientValue.faena.responsable;
    }

    ngAfterViewInit(): void {
        if (this.dashboard_enabled) {

            this.tableauService.GetTableau({
                faenaId: this.authService.currentClientValue.faena.faenaId,
                preferred_username: this.authService.currentUserValue.preferred_username,
                userId: this.authService.currentUserValue.userId
            }).then(response => {
                if (response.code == 200 && response.data.dashboard_enabled) {
                    this.initTableau(response.data.url_dashboard, response.data.token);
                } else {
                    this.dashboard_enabled = false;
                }
            });
        }
    }

    loadScripts(): void {
        let body = document.body;
        let script = document.createElement('script');
        script.type = 'module';
        script.src = environment.external_script.tableau_copec_js; // Aquí va la URL de tu script
        body.appendChild(script);

    }

    initTableau_2(vizUrl: string, token: string) {
        const options = {
            id: "tableauVizExt",
            hideTabs: false,
            token: `${token}`
        };
        this.viz = new tableau.Viz(
            this.containerDiv!.nativeElement,
            vizUrl,
            options
        );
    }

    initTableau(vizUrl: string, token: string) {

        const container = document.getElementById('tableauVizView');
        container!.innerHTML = '<tableau-viz ' +
            'id="tableauVizExt" ' +
            `src=${vizUrl} ` +
            `token=${token} ` +
            'toolbar="hidden"> ' +            
            `<viz-filter field="id_solicitante" value=${this.authService.currentClientValue.faena.faenaId}></viz-filter>` + 
            '</tableau-viz>';
    }
}
