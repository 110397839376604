<div class="container-fluid p-0">
  <div class="row mb-2 mb-xl-3">
    <div class="col-auto d-none d-sm-block">
      <h3>Agregar muestra</h3>
    </div>
    <div class="col-auto ms-auto text-end mt-n1"></div>
  </div>
  <div class="row justify-content-center">

    <div class="col-10">
      <ul class="stepper stepper-horizontal">

        <li class="completed">
          <a [routerLink]="" class="px-2"> <span class="circle">1</span><span class="label">Datos del componente</span> </a>
        </li>


        <li class="active">
          <a [routerLink]="" class="px-2"> <span class="circle">2</span><span class="label">Datos de la muestra</span> </a>
        </li>


        <li>
          <a [routerLink]="" class="px-2"> <span class="circle">3</span><span class="label">Comprobante</span> </a>
        </li>
      </ul>

    </div>
  </div>

  <form #registraMuestraForm="ngForm" class="row justify-content-between">
    <div class="col-12 col-md-6 mb-4">
      <div class="card text-dark mb-0 border h-100">
        <div class="card-header bg-light"><strong id="agregar-muestra-componente-equipo">COMPONENTE Y EQUIPO</strong><mat-spinner *ngIf="model.loading" matSuffix [diameter]="18" style="float: right; margin-left: 8px"></mat-spinner></div>
        <div class="card-body" *ngIf="model.componente">
            <div class="row">
                <div class="col">
                    <p class="card-text mb-1">{{model.componente.tipoComponente.nombre}}<br> {{model.componente.marca}}<br> {{!model.componente.modelo ?'Sin modelo'
                         : model.componente.modelo}}<br> {{model.componente.ubicacion}}
                        <br>
                    </p>
                    <h4 class="card-text mb-1"><span class="badge bg-secondary">ID {{model.componente.componenteId}}</span></h4>
                </div>
                <div class="col text-end">
                    <p class="card-text mb-1">
                        {{model.componente.equipo?.tipoEquipo?.nombre}}<br> {{model.componente.equipo?.marca}}
                        <br> {{model.componente.equipo?.modelo}}
                    </p>
                </div>
            </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 mb-4">
      <div class="card text-dark mb-0 border h-100">
        <div class="card-header bg-light"><strong>MUESTRA</strong></div>
        <div class="card-body" *ngIf="model.componente">
            <div class="mb-3">
                <label class="form-label">Número envase de la muestra</label>
                <input type="text"
                    id="numeroEnvaseMuestra" name="numeroEnvaseMuestra"  class="form-control"
                    maxlength="9"  min="1"
                    [(ngModel)]="model.registroMuestra.muestraId"  [unicoNumeroEnvase]=[]
                    (change)="changeNumeroEnvase()"
                    #numeroEnvaseMuestra="ngModel"
                    validaMuestraId
                    [ngClass]="{'is-invalid':numeroEnvaseMuestra.invalid
                        && (numeroEnvaseMuestra.dirty || numeroEnvaseMuestra.touched) || model.muestraIdUtilizado }" required
                        >
                    <div *ngIf="numeroEnvaseMuestra.invalid && (numeroEnvaseMuestra.dirty || numeroEnvaseMuestra.touched) || model.muestraIdUtilizado" class="text-danger">
                    <div *ngIf="numeroEnvaseMuestra.errors?.required">
                        Debe ingresar el n&uacute;mero envase de la muestra.
                    </div>
                    <div *ngIf="numeroEnvaseMuestra.errors?.unicoNumeroEnvase || model.muestraIdUtilizado">
                        Número de envase utilizado anteriormente.
                    </div>
                    <div *ngIf="numeroEnvaseMuestra.value !=='' && numeroEnvaseMuestra.errors?.validacionRut">
                        Formato incorrecto.
                    </div>
                </div>
              </div>
            <div class="mb-3">
                <label class="form-label">Fecha toma de muestra</label>
                <input type="date" class="form-control" onkeydown="return false"
                id="fechaTomaMuestra" name="fechaTomaMuestra"
                max="{{ model.todaysdate | date :'yyyy-MM-dd' }}" validarFecha
                [(ngModel)]="model.registroMuestra.fechaMuestreo"
                #fechaTomaMuestra="ngModel"
                [ngClass]="{'is-invalid':fechaTomaMuestra.invalid
                        && (fechaTomaMuestra.dirty || fechaTomaMuestra.touched)}" required>
                <div *ngIf="fechaTomaMuestra.invalid && (fechaTomaMuestra.dirty || fechaTomaMuestra.touched)" class="text-danger">
                    <div *ngIf="fechaTomaMuestra.errors?.required">
                        Debe seleccionar la fecha de toma de muestra.
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
    <div class="col-12 mb-4" *ngIf="datoMuestra">
      <div class="card text-dark mb-0 border">
        <div class="card-header bg-light">
          <strong>DATOS DE LA MUESTRA</strong>
          <mat-spinner *ngIf="model.loading" matSuffix [diameter]="18" style="float: right; margin-left: 8px"></mat-spinner>
        </div>
        <div class="card-body" *ngIf="model.componente">
          <div class="row justify-content-between">
            <div class="col-12 col-md-6 mb-4 mb-md-0 d-flex flex-column">
              <div class="mb-3">
                <label class="form-label">Lubricante</label>
                <ng-select id="lubricanteIdSeleccionado" name="lubricanteIdSeleccionado"
                    [(ngModel)]="model.registroMuestra.lubricante"
                    #selectLubricante="ngModel"
                    #select
                    [items]="model.lubricantes"
                    name="lubricante"
                    bindLabel="nombre"y
                    [clearable]="false"
                    [ngClass]="{'is-invalid':selectLubricante.invalid && (selectLubricante.dirty || selectLubricante.touched)}"
                    required>

                    <ng-template ng-footer-tmp *ngIf="!model.verOtrosLubricantes">
                        <a (click)="verOtrosLubricantes(select);false">
                            ver m&aacute;s ...
                        </a>
                    </ng-template>
                    <ng-template ng-footer-tmp *ngIf="model.verOtrosLubricantes">
                        <div class="row">
                            <div class="col">
                                <a (click)="verLubricantesRecomendados(select);false">
                                    Volver
                                </a>
                            </div>
                            <div class="col text-end">
                                <a (click)="seleccionarOtroLubricante(select);false">
                                   Otro
                                </a>
                            </div>
                        </div>
                    </ng-template>

                </ng-select>
                <div *ngIf="selectLubricante.invalid && (selectLubricante.dirty || selectLubricante.touched)" class="text-danger">
                    <div *ngIf="selectLubricante.errors?.required">
                        Debe seleccionar lubricante.
                    </div>
                </div>
              </div>
              <div class="mb-3" *ngIf="model.registroMuestra.lubricante?.lubricanteId==='0'">
                <label class="form-label">Otro lubricante</label>
                <input type="text"
                    id="otroLubricante" name="otroLubricante"  class="form-control" maxlength="100"
                    [(ngModel)]="model.registroMuestra.otroLubricante"
                    #otroLubricante="ngModel"
                    [ngClass]="{'is-invalid':otroLubricante.invalid
                        && (otroLubricante.dirty || otroLubricante.touched)}" required
                        >
                <div *ngIf="otroLubricante.invalid && (otroLubricante.dirty || otroLubricante.touched)" class="text-danger">
                    <div *ngIf="otroLubricante.errors?.required">
                        Debe ingresar otro lubricante.
                    </div>
                </div>

                <div class="alert alert-warning mt-2" role="alert">
                    <div class="alert-message">
                        <strong>Importante!</strong> Deberá incluir adicionalmente una muestra de este lubricante nuevo, <strong>sin uso</strong>, para que el laboratorio pueda analizarlo.
                    </div>
                </div>
              </div>
              
              <div class="mb-3">
                <label class="form-label">Plan de análisis</label>
                <select id="contratoPlanAnalisisId" name="contratoPlanAnalisisId"
                    class="form-select"
                    aria-label="Default select example"
                    [(ngModel)]="model.registroMuestra.planAnalisis"
                    #selectContratoPlanAnalisisId="ngModel"
                    [ngClass]="{'is-invalid':!model.registroMuestra.planAnalisis && selectContratoPlanAnalisisId.invalid && (selectContratoPlanAnalisisId.dirty || selectContratoPlanAnalisisId.touched)}"
                    required>
                    <option [ngValue]="p" *ngFor="let p of model.planesAnalisis">{{p.nombre}}</option>
                </select>
                <div *ngIf="!model.registroMuestra.planAnalisis && selectContratoPlanAnalisisId.invalid && (selectContratoPlanAnalisisId.dirty || selectContratoPlanAnalisisId.touched)" class="text-danger">
                    <div *ngIf="selectContratoPlanAnalisisId.errors?.required">
                        Debe seleccionar plan de análisis.
                    </div>
                </div>
            </div>
            </div>
            <div class="col-12 col-md-6 d-flex flex-column">
              <div class="mb-3">
                <label class="form-label">Uso total del componente <em>(opcional)</em></label>
                <div class="row">
                    <div class="col-sm-6">
                        <input type="text" id="usoTotalComponente" name="usoTotalComponente" maxlength="10"  class="form-control" numeric decimals="0"
                        [(ngModel)]="model.registroMuestra.usoTotalComponente">
                    </div>
                    <div class="col mt-1">
                        <mat-radio-group id="unidadUsoTotalComponente" name="unidadUsoTotalComponente" aria-label="Select an option"
                        [(ngModel)]="model.registroMuestra.unidadUsoTotalComponente" color="primary"
                        #unidadUsoTotalComponente="ngModel"
                        >
                            <mat-radio-button value="horas">Horas</mat-radio-button>
                            <mat-radio-button value="km">KM</mat-radio-button>
                        </mat-radio-group>
                        <div  class="text-danger">
                            <div *ngIf="isRequeredUnidadUsoTotalComponente()">
                                Debe seleccionar unidad.
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div class="mb-3">
                  <label class="form-label">Uso desde el cambio del lubricante <em>(opcional)</em></label>
                  <div class="row">
                      <div class="col-sm-6">
                          <input type="text" id="usoCambioLubricante" name="usoCambioLubricante" class="form-control" numeric decimals="0" maxlength="10" [(ngModel)]="model.registroMuestra.usoCambioLubricante">
                      </div>
                      <div class="col mt-1">
                          <mat-radio-group id="unidadUsoCambioLubricante" name="unidadUsoCambioLubricante" aria-label="Select an option" [(ngModel)]="model.registroMuestra.unidadUsoCambioLubricante" color="primary"
                          #unidadUsoCambioLubricante="ngModel"
                          ><mat-radio-button value="horas">Horas</mat-radio-button>
                              <mat-radio-button value="km">KM</mat-radio-button>
                          </mat-radio-group>
                          <div  class="text-danger">
                              <div *ngIf="isRequeredUnidadUsoCambioLubricante()">
                                  Debe seleccionar unidad.
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="mb-3">
                  <label class="form-label">Relleno desde el último cambio <em>(opcional)</em></label>
                  <div class="row">
                      <div class="col-sm-6">
                          <input type="text" id="rellenoDesdeUltimoCambio" name="rellenoDesdeUltimoCambio" maxlength="10"  class="form-control" numeric decimals="0" [(ngModel)]="model.registroMuestra.rellenoDesdeUltimoCambio">
                      </div>
                      <div class="col mt-1">Litros</div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="card text-dark mb-0 border">
        <div class="card-header bg-light">
          <strong>COMENTARIO (opcional)</strong>
          <mat-spinner *ngIf="false" matSuffix [diameter]="18" style="float: right; margin-left: 8px"></mat-spinner>
        </div>
        <div class="card-body">
          <label class="form-label">Comentario (75 caracteres)</label>
          <input type="text" id="comment" name="comment"  class="form-control" maxlength="75"  min="1" [(ngModel)]="model.registroMuestra.comentarioCliente">
        </div>
      </div>
    </div>
    <div class="col-12 mt-4 mb-5 text-end">
      <a id="agregar-muestra-cancelar" class="btn btn-link btn-lg" [routerLink]="['/busqueda-componente']">Cancelar</a>
      <button id="agregar-muestra-ingresar-muestra" class="btn btn-success btn-lg"  [disabled]="registraMuestraForm.invalid || isRequeredRadiosUnidades() || model.muestraIdUtilizado"  (click)="ingresarMuestra()">Ingresar la muestra</button>
    </div>
  </form>


</div>
