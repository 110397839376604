import { Component, OnInit } from '@angular/core';

import { AuthenticationService } from 'src/app/shared/auth/authentication.service';
import { RegistraMuestraModels } from '../registra-models/registra.models';
import { RegistraMuestraViewModels } from '../registra-models/registra.view.models';
import { RegistraService } from '../registra-services/registra.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { SampleBoxDataProvider } from '../../../shared/services/sampleBoxDataProvider.service';
import { SpinnerService } from '../../../shared/services/spinner.service';
import { Objects } from 'src/app/shared';
import { LogService } from 'src/app/shared/services/log.service';

@Component({
    selector: 'app-busqueda-componente',
    templateUrl: './registra.component.html',
    styleUrls: ['./registra.component.css']
})
export class RegistraMuestraComponent implements OnInit {
    datoMuestra = true;
    model: RegistraMuestraViewModels.ViewModel = {
        lubricanteOtro: {
            nombre: "Otro",
            lubricanteId: "0",
            code: '',
            tipo: undefined
        },
        textoLubricanteBuscado: '',
        lubricantes: [],
        lubricantesTodos: [],
        planesAnalisis: [],
        lubricantesFiltrados: [],
        verOtrosLubricantes: false,
        registroMuestra: {
            muestraId: '',
            otroLubricante: '',
            fechaMuestreo: '',
            componente:{
                componenteId:'',
                marca:'',
                modelo:'',
                ubicacion:'',
                clienteId:'',
                lubricante:{
                    nombre:'',
                    lubricanteId:'',
                    code: '',
                    tipo: undefined
                },
                planAnalisis:{
                    nombre:'',
                    planAnalisisId:''
                },
                solicitante:'',
                tipoComponente:{nombre:'',tipoComponenteId:''}
            },
        }
        ,muestraIdUtilizado:false
        ,loading:false
        ,registrandoMuestra:false,
        todaysdate:new Date()


    };
    constructor(
        private authService: AuthenticationService,
        private service: RegistraService,
        private route: ActivatedRoute,
        private router: Router,
        public appData: SampleBoxDataProvider,
        private spinner:SpinnerService,
        private _logService: LogService,

    ) {

    }

    ngOnInit(): void {
        let data = this.route.snapshot.queryParams.data;
        this.model.componente =  JSON.parse(data);
        if (this.model.componente?.componenteId) {
            this.spinner.off();
            this.getMuestraInfo(this.model.componente.componenteId);
            this.getLubricantes();
            this.model.registroMuestra.unidadUsoCambioLubricante="horas"
            this.model.registroMuestra.unidadUsoTotalComponente="horas"
        }

    }
    ngAfterViewInit() {
    }

    changeNumeroEnvase(){
        this.model.muestraIdUtilizado=false;
    }

    getLubricantes(){
        this.service.GetLubricantes()
        .then(response => {
            if (response.success) {
                this.model.lubricantes = response.data.sort((a, b) => a.nombre.localeCompare(b.nombre));
                this.model.lubricantesTodos=this.model.lubricantes
            } else {
                console.error("Se ha presentado un error al obtener lubricantes.")
            }
        });
    }



    searchLubricante(term: string, item: RegistraMuestraModels.Lubricante) {
        return item.nombre.toLowerCase().indexOf(term.toLowerCase()) > -1 || item.lubricanteId.toString().toLowerCase().indexOf(term.toLowerCase()) > -1;
    }

    filtrarOtrosLubricantes() {
        let term = this.model.textoLubricanteBuscado;
        this.model.lubricantesFiltrados = this.model.lubricantesTodos.filter(item => {
            return term.trim() === '' || item.nombre.toLowerCase().indexOf(term.toLowerCase()) > -1;
        });
    }

    getMuestraInfo(componenteId: string): void {
        const request: RegistraMuestraModels.GetMuestraInfoRequest = {
            clienteId: this.authService.currentClientValue.clienteId,
            faenaId: this.authService.currentClientValue.faena.faenaId,
            componenteId: componenteId
        };
        this.model.loading=true;
        this.service.GetMuestraInfo(request)
            .then(response => {
                if (response.success) {
                    this.model.componente = response.data.componente;
                    this.model.lubricantes = response.data.lubricantes;
                    this.model.planesAnalisis = response.data.planesAnalisis;
                    this.model.lubricantesTodos = response.data.lubricantesTodos;

                    this.model.registroMuestra.componente = response.data.componente;
                    this.model.registroMuestra.lubricante = this.model.componente.lubricante;
                    this.model.registroMuestra.planAnalisis = this.model.planesAnalisis.find(x => x.planAnalisisId === this.model.componente?.planAnalisis.planAnalisisId) ?? this.model.planesAnalisis[0] ?? undefined;

                    this.model.lubricantesFiltrados = response.data.lubricantes;
                }
            })
            .catch(error => {
                console.error(error);
            }).finally(()=>{
                this.model.loading=false;
            });
    }

    verOtrosLubricantes(select: NgSelectComponent): void {
        this.model.verOtrosLubricantes = true;
        this.service.GetLubricantes().then(resp=>{
            if(resp.data){
                resp.data.push(this.model.lubricanteOtro);

                this.model.lubricantesFiltrados=resp.data;
                this.model.lubricantesTodos= this.model.lubricantesFiltrados;
            }
        });
    }
    seleccionarOtroLubricante(select: NgSelectComponent){
        this.model.registroMuestra.lubricante=this.model.lubricanteOtro;
        select.close();
    }
    verLubricantesRecomendados(select: NgSelectComponent): void {
        this.model.verOtrosLubricantes = false;
        this.model.lubricantesFiltrados = this.model.lubricantes;
    }

    ingresarMuestra(): void {
        if(this.model.registrandoMuestra){
            return
        }
        this.model.registrandoMuestra=true;
        this.model.registroMuestra.usuario = this.authService.current_user;

        this.appData.loadCajaDeMuestras(true).then(data=>{
            let existeMuestra=data.muestras.filter(muestra=>{
                return muestra.muestraId ===this.model.registroMuestra.muestraId;
            }).length>0;
            if(existeMuestra){
                let nroActual = this.model.registroMuestra.muestraId;
                this.model.registroMuestra.muestraId="";
                setTimeout(()=>{
                    this.model.registroMuestra.muestraId=nroActual;
                },200);
            }else{
                this.model.registroMuestra.fechaIngreso=new Date;

                this.service.setMuestraValid({
                    clienteId:this.authService.currentClientValue.clienteId,
                    faenaId:this.authService.currentClientValue.faena.faenaId,
                    muestraId:this.model.registroMuestra.muestraId
                }).toPromise().then(r=>{

                   if(r.data.isValid ){

                    let rq: Objects.LogInput = {
                        accion: 'New',
                        elemento: this.model.registroMuestra.muestraId,
                        tipoElemento: 'Muestra',
                        usuario: this.authService.usuario.nombre,
                        usuarioId: this.authService.usuario.userId,
                        ambiente: 'Web Cliente'
                      }
                    this._logService.createLog(rq, true);

                       this.appData.addMuestraSCP(this.model.registroMuestra).then(r=>{

                        let registroMuestra = JSON.stringify([this.model.registroMuestra]);
                           this.router.navigate(['/comprobante-muestra'], {
                               queryParams: {registroMuestras: registroMuestra}
                               , skipLocationChange: true
                           });
                       });
                   }else{
                        this.model.muestraIdUtilizado=true;
                   }

                });
            }
        }).finally(()=>{
            this.model.registrandoMuestra=false;
        });

    }

    isEmpty(value?: string): boolean {
        return !value || value.trim().length == 0;
    }

    isRequeredUnidadUsoCambioLubricante(): boolean {
        return !this.isEmpty(this.model.registroMuestra.usoCambioLubricante) && !this.model.registroMuestra.unidadUsoCambioLubricante;
    }
    isRequeredUnidadUsoTotalComponente(): boolean {
        return !this.isEmpty(this.model.registroMuestra.usoTotalComponente) && !this.model.registroMuestra.unidadUsoTotalComponente;
    }

    isRequeredRadiosUnidades(): boolean {
        return this.isRequeredUnidadUsoTotalComponente() || this.isRequeredUnidadUsoCambioLubricante();
    }


}
